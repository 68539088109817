<template>
    <div>
        <div class="row my-0">
            <div class="col-12">
                <h1 class="mb-4">{{ SHARED.SupportCenterPage.TermsAndConditions.Statement.Title }}</h1>
                <p>{{ SHARED.SupportCenterPage.TermsAndConditions.Statement.Description }}</p>
            </div>
        </div>
        <recess-divider show-line />
        <div class="row">
            <div class="col-12">
                <h3>{{ SHARED.SupportCenterPage.TermsAndConditions.Statement.Information.Block1.Title }}</h3>
                <p>{{ SHARED.SupportCenterPage.TermsAndConditions.Statement.Information.Block1.Paragraph1 }}</p>
                <div class="ml-4">
                    <ul class="u-list-unordered mt-4 mb-4">
                        <li v-for="(value, key) in list.Block1.List" :key="key">
                            {{ SHARED.SupportCenterPage.TermsAndConditions.Statement.ListOptions[value] }}
                        </li>
                        <ul class="mt-4">
                            <li v-for="(value, key) in list.Block1.InnerList" :key="key">
                                {{ SHARED.SupportCenterPage.TermsAndConditions.Statement.ListOptions.InnerList[value] }}
                            </li>
                        </ul>
                    </ul>
                </div>
            </div>
            <div class="col-12 mt-5">
                <h3>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement.Information.Block2.Title }}</h3>
                <p>{{ SHARED.SupportCenterPage.TermsAndConditions.Statement.Information.Block2.Description }}</p>
                <h4 class="mt-4">{{ SHARED.SupportCenterPage.TermsAndConditions.Statement.Information.Block2.Type1.Title }} </h4>
                <div class="ml-4">
                    <h6 class="u-text-bold mt-4">{{ SHARED.SupportCenterPage.TermsAndConditions.Statement.InterestConsent }}</h6>
                    <ul class="u-list-unordered mt-4 mb-4">
                        <li v-for="(value, key) in list.Block2.Type1.List" :key="key">
                            {{ SHARED.SupportCenterPage.TermsAndConditions.Statement.ListOptions[value] }}
                        </li>
                        <ul class="mt-4">
                            <li v-for="(value, key) in list.Block2.Type1.InnerList" :key="key">
                                {{ SHARED.SupportCenterPage.TermsAndConditions.Statement.ListOptions.InnerList[value] }}
                            </li>
                        </ul>
                    </ul>
                    <p>{{ SHARED.SupportCenterPage.TermsAndConditions.Statement.DataStorage.LegimateInterest }}</p>
                </div>
                <p>{{ SHARED.SupportCenterPage.TermsAndConditions.Statement.Information.Block2.Paragraph1 }}</p>
            </div>
            <div class="col-12 mt-5">
                <h4> {{ SHARED.SupportCenterPage.TermsAndConditions.Statement.Information.Block2.Type2.Title }} </h4>
                <div class="ml-4">
                    <h6 class="u-text-bold mt-4">{{ SHARED.SupportCenterPage.TermsAndConditions.Statement.InterestConsent }}</h6>
                    <ul class="u-list-unordered mt-4 mb-4">
                        <li v-for="(value, key) in list.Block2.Type2.List" :key="key">
                            {{ SHARED.SupportCenterPage.TermsAndConditions.Statement.ListOptions[value] }}
                        </li>
                        <ul class="mt-4">
                            <li v-for="(value, key) in list.Block2.Type2.InnerList" :key="key">
                                {{ SHARED.SupportCenterPage.TermsAndConditions.Statement.ListOptions.InnerList[value] }}
                            </li>
                        </ul>
                    </ul>
                    <p>{{ SHARED.SupportCenterPage.TermsAndConditions.Statement.DataStorage.Demo }}</p>
                </div>
            </div>
            <div class="col-12 mt-5">
                <h4>{{ SHARED.SupportCenterPage.TermsAndConditions.Statement.Information.Block2.Type3.Title }}</h4>
                <div class="ml-4">
                    <h6 class="u-text-bold mt-4"> {{ SHARED.SupportCenterPage.TermsAndConditions.Statement.InterestConsent }} </h6>
                    <ul class="u-list-unordered mt-4 mb-4">
                        <li v-for="(value, key) in list.Block2.Type3.List" :key="key">
                            {{ SHARED.SupportCenterPage.TermsAndConditions.Statement.ListOptions[value] }}
                        </li>
                        <ul class="mt-4">
                            <li v-for="(value, key) in list.Block2.Type3.InnerList" :key="key">
                                {{ SHARED.SupportCenterPage.TermsAndConditions.Statement.ListOptions.InnerList[value] }}
                            </li>
                        </ul>
                    </ul>
                    <p>{{ SHARED.SupportCenterPage.TermsAndConditions.Statement.DataStorage.LegimateInterest }}</p>
                </div>
            </div>
            <div class="col-12 mt-5">
                <h4>{{ SHARED.SupportCenterPage.TermsAndConditions.Statement.Information.Block2.Type4.Title }}</h4>
                <div class="ml-4">
                    <h6 class="u-text-bold mt-4">{{ SHARED.SupportCenterPage.TermsAndConditions.Statement.InterestConsent }}</h6>
                    <ul class="u-list-unordered mt-4 mb-4">
                        <li v-for="(value, key) in list.Block2.Type4.List" :key="key">
                            {{ SHARED.SupportCenterPage.TermsAndConditions.Statement.ListOptions[value] }}
                        </li>
                        <ul class="mt-4">
                            <li v-for="(value, key) in list.Block2.Type4.InnerList" :key="key">
                                {{ SHARED.SupportCenterPage.TermsAndConditions.Statement.ListOptions.InnerList[value] }}
                            </li>
                        </ul>
                    </ul>
                    <p>{{ SHARED.SupportCenterPage.TermsAndConditions.Statement.DataStorage.LegimateInterest }}</p>
                </div>
            </div>
            <div class="col-12 mt-5">
                <h4>{{ SHARED.SupportCenterPage.TermsAndConditions.Statement.Information.Block2.Type5.Title }} </h4>
                <div class="ml-4">
                    <h6 class="u-text-bold mt-4">{{ SHARED.SupportCenterPage.TermsAndConditions.Statement.Information.Block2.Type5.Subtitle }}</h6>
                    <ul class="u-list-unordered mt-4 mb-4">
                        <li v-for="(value, key) in list.Block2.Type5.List" :key="key">
                            {{ SHARED.SupportCenterPage.TermsAndConditions.Statement.ListOptions[value] }}
                        </li>
                    </ul>
                    <p>{{ SHARED.SupportCenterPage.TermsAndConditions.Statement.Information.Block2.Type5.Paragraph1 }}</p>
                </div>
            </div>
            <div class="col-12 mt-5">
                <h4>{{ SHARED.SupportCenterPage.TermsAndConditions.Statement.Information.Block2.Type6.Title }}</h4>
                <div class="ml-4">
                    <h6 class="u-text-bold mt-4">{{ SHARED.SupportCenterPage.TermsAndConditions.Statement.InterestConsent }}</h6>
                    <ul class="u-list-unordered mt-4 mb-4">
                        <li v-for="(value, key) in list.Block2.Type6.List" :key="key">
                            {{ SHARED.SupportCenterPage.TermsAndConditions.Statement.ListOptions[value] }}
                        </li>
                        <ul class="mt-4">
                            <li v-for="(value, key) in list.Block2.Type6.InnerList" :key="key">
                                {{ SHARED.SupportCenterPage.TermsAndConditions.Statement.ListOptions.InnerList[value] }}
                            </li>
                        </ul>
                    </ul>
                    <p>{{ SHARED.SupportCenterPage.TermsAndConditions.Statement.Information.Block2.Type6.Paragraph1 }}</p>
                </div>
            </div>
            <div class="col-12 mt-5">
                <h3>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement.Information.Block3.Title }}</h3>
                <p>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement.Information.Block3.Paragraph1 }}</p>
            </div>
            <div class="col-12 mt-5">
                <h3>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement.Information.Block4.Title }}</h3>
                <p>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement.Information.Block4.Paragraph1 }}</p>
                <p>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement.Information.Block4.Paragraph2 }}</p>
                <p>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement.Information.Block4.Paragraph3 }}</p>
            </div>
            <div class="col-12 mt-5">
                <h3>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement.Information.Block5.Title }}</h3>
                <p>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement.Information.Block5.Paragraph1 }}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mt-5">
                <h1 class="mb-4">{{ SHARED.SupportCenterPage.TermsAndConditions.Statement2.Title }}</h1>
            </div>
            <recess-divider show-line />
            <div class="col-12 mt-5">
                <h3>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement2.Information.Block1.Title }}</h3>
                <p>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement2.Information.Block1.Paragraph1 }}</p>
            </div>
            <div class="col-12 mt-5">
                <h3>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement2.Information.Block2.Title }}</h3>
                <p>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement2.Information.Block2.Paragraph1 }}</p>
            </div>
            <div class="col-12 mt-5">
                <h3>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement2.Information.Block3.Title }}</h3>
                <p>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement2.Information.Block3.Paragraph1 }}</p>
                <p>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement2.Information.Block3.Paragraph2 }}</p>
                <p>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement2.Information.Block3.Paragraph3 }}</p>
            </div>
            <div class="col-12 mt-5">
                <h3>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement2.Information.Block4.Title }}</h3>
                <p>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement2.Information.Block4.Paragraph1 }}</p>
            </div>
            <div class="col-12 mt-5">
                <h3>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement2.Information.Block5.Title }}</h3>
                <p>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement2.Information.Block5.Paragraph1 }}</p>
            </div>
            <div class="col-12 mt-5">
                <h3>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement2.Information.Block6.Title }}</h3>
                <p>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement2.Information.Block6.Paragraph1 }}</p>
                <ul class="u-list-unordered mt-4 ml-4 mb-4">
                    <li v-for="(item, id) in SHARED.SupportCenterPage.TermsAndConditions.Statement2.Information.Block6.List" :key="id" v-html="item"></li>
                </ul>
                <p>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement2.Information.Block6.Paragraph2 }}</p>
                <p>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement2.Information.Block6.Paragraph3 }}</p>
                <p v-html="SHARED.SupportCenterPage.TermsAndConditions.Statement2.Information.Block6.Paragraph4"></p>
            </div>
            <div class="col-12 mt-5">
                <h3>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement2.Information.Block7.Title }}</h3>
                <p v-html="SHARED.SupportCenterPage.TermsAndConditions.Statement2.Information.Block7.Paragraph1"></p>
            </div>
            <div class="col-12 mt-5">
                <h3>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement2.Information.Block8.Title }}</h3>
                <p v-html="SHARED.SupportCenterPage.TermsAndConditions.Statement2.Information.Block8.Paragraph1"></p>
            </div>
            <div class="col-12 mt-5">
                <h3>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement2.Information.Block9.Title }}</h3>
                <p>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement2.Information.Block9.Paragraph1 }}</p>
                <p>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement2.Information.Block9.Paragraph2 }}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mt-5">
                <h1 class="mb-4">{{ SHARED.SupportCenterPage.TermsAndConditions.Statement3.Title }}</h1>
            </div>
            <recess-divider show-line />
            <div class="col-12 mt-5">
                <p>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement3.Information.Block1.Paragraph1 }}</p>
                <p>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement3.Information.Block1.Paragraph2 }}</p>
                <p>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement3.Information.Block1.Paragraph3 }}</p>
                <p>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement3.Information.Block1.Paragraph4 }}</p>
                <p>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement3.Information.Block1.Paragraph3 }}</p>
                <p>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement3.Information.Block1.Paragraph6 }}</p>
            </div>
            <div class="col-12 mt-5">
                <h3>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement3.Information.Block2.Title }}</h3>
                <p>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement3.Information.Block2.Paragraph1 }}</p>
                <p v-html="SHARED.SupportCenterPage.TermsAndConditions.Statement3.Information.Block2.Paragraph2"></p>
                <p>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement3.Information.Block2.Paragraph3 }}</p>
            </div>
            <div class="col-12 mt-5">
                <h3>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement3.Information.Block3.Title }}</h3>
                <p>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement3.Information.Block3.Paragraph1 }}</p>
            </div>
            <div class="col-12 mt-5">
                <h3>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement3.Information.Block4.Title }}</h3>
                <p>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement3.Information.Block4.Paragraph1 }}</p>
            </div>
            <div class="col-12 mt-5">
                <h3>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement3.Information.Block5.Title }}</h3>
                <p>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement3.Information.Block5.Paragraph1 }}</p>
                <p>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement3.Information.Block5.Paragraph2 }}</p>
            </div>
            <div class="col-12 mt-5">
                <h3>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement3.Information.Block6.Title }}</h3>
                <p>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement3.Information.Block6.Paragraph1 }}</p>
            </div>
            <div class="col-12 mt-5">
                <h3>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement3.Information.Block7.Title }}</h3>
                <p v-html="SHARED.SupportCenterPage.TermsAndConditions.Statement3.Information.Block7.Paragraph1"></p>
            </div>
            <div class="col-12 mt-5">
                <h3>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement3.Information.Block8.Title }}</h3>
                <p>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement3.Information.Block8.Paragraph1 }}</p>
                <p>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement3.Information.Block8.Paragraph2 }}</p>
                <p v-html="SHARED.SupportCenterPage.TermsAndConditions.Statement3.Information.Block8.Paragraph3"></p>
                <p v-html="SHARED.SupportCenterPage.TermsAndConditions.Statement3.Information.Block8.Paragraph4"></p>
                <p v-html="SHARED.SupportCenterPage.TermsAndConditions.Statement3.Information.Block8.Paragraph5"></p>
                <p>{{  SHARED.SupportCenterPage.TermsAndConditions.Statement3.Information.Block8.Paragraph6 }}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12 d-flex justify-content-end">
                <recess-button variant="secondary" class="mt-4 mb-4" :title="SHARED.ButtonTexts.goToTop" @click.prevent.native="scrollToTop()" />
            </div>
        </div>
    </div>
</template>

<script>
import { SHARED } from "../../constants/EdumsSharedConstants.json";

export default {
    Name: "TermsAndConditions",
    data() {
        return {
            SHARED,
            list: {
                Block1: {
                    List: ['NAW', 'DatePlaceBirth', 'EmailAddress', 'PhoneNumber', 'Company', 'Communication', 'FinancialData', 'Cookie'],
                    InnerList: ['BrowserIP', 'VisitsClicksEmail']
                },
                Block2: {
                    Type1: {
                        List: ['NAW', 'EmailAddress', 'PhoneNumber', 'Cookie'],
                        InnerList: ['BrowserIP', 'VisitsClicksEmail'] 
                    },
                    Type2: {
                        List: ['NAW', 'EmailAddress', 'PhoneNumber', 'Company', 'Cookie'],
                        InnerList: ['BrowserIP', 'VisitsClicksEmail'] 
                    },
                    Type3: {
                        List: ['NAW', 'EmailAddress', 'PhoneNumber', 'Cookie'],
                        InnerList: ['BrowserIP', 'VisitsClicksEmail'] 
                    },
                    Type4: {
                        List: ['NAW', 'EmailAddress', 'PhoneNumber', 'Company', 'Cookie'],
                        InnerList: ['BrowserIP', 'VisitsClicksEmail'] 
                    },
                    Type5: {
                        List: ['NAW', 'EmailAddress', 'PhoneNumber', 'Communication', 'Company'],
                    },
                    Type6: {
                        List: ['NAW', 'EmailAddress', 'PhoneNumber', 'Communication', 'Company', 'Cookie'],
                        InnerList: ['BrowserIP', 'VisitsClicksEmail'] 
                    }
                }
            }            
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }
}

</script>
